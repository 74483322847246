@tailwind base;
@tailwind components;
@tailwind utilities;
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";
body {
  margin: 0;
  padding: 0;
}

.chrome-picker {
  width: 450px !important;
  padding: 10px !important;
  border-radius: 8px !important;
  box-shadow: 0px 4px 6px 0px rgba(31, 41, 55, 0.05),
    0px 10px 15px 0px rgba(31, 41, 55, 0.1) !important;
}

@media (max-width: 599px) {
  .chrome-picker {
    padding: 0px !important;
    width: 100% !important;
  }
}

.slick-dots li button:before {
  color: #4b4b87 !important;
  font-size: 12px !important;
}

.slick-dots li.slick-active button:before {
  color: #fc53a4 !important;
  font-size: 12px !important;
}

#root
  > div
  > div.container
  > div
  > div
  > div
  > div
  > div
  > div
  > div.items-center.justify-center.grid.pt-24.px-6
  > div:nth-child(1)
  > div
  > div
  > ul {
  display: flex !important;
  margin-left: -10px !important;
}

#root
  div
  div.\32xl\:flex.lg\:flex.\32xl\:pt-0.pt-14
  div.\32xl\:w-\[50\%\].w-full.\32xl\:pt-20.lg\:pt-10.pt-10.flex.justify-center
  div
  div.w-full.mt-8
  div
  div:nth-child(2)
  div:nth-child(2)
  div:nth-child(2)
  div {
  margin-top: 0px !important;
}

::-webkit-scrollbar {
  width: 6px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #01010b59;
  border-radius: 8px;
  height: 30px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #01010b8f;
}
